import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'


import { useHead } from '@unhead/vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute()
const translateService = useI18n()

useHead({
  title: translateService.t('title'),
  meta: [
    {
      name: 'name',
      property: 'name',
      content: translateService.t('og_title') as string
    },
    {
      name: 'description',
      property: 'description',
      content: translateService.t('og_description') as string
    },

    {
      name: 'og:title',
      property: 'og:title',
      content: translateService.t('og_title') as string
    }, {
      name: 'og:description',
      property: 'og:description',
      content: translateService.t('og_description') as string
    }, {
      name: 'twitter:title',
      property: 'twitter:title',
      content: translateService.t('og_title') as string
    }, {
      name: 'twitter:description',
      property: 'twitter:description',
      content: translateService.t('og_description') as string
    }, {
      name: 'description',
      property: 'description',
      content: translateService.t('og_description') as string
    }, {
      name: 'generator',
      property: 'generator',
      content: translateService.t('og_title') as string
    }, {
      name: 'twitter:image:alt',
      property: 'twitter:image:alt',
      content: translateService.t('image_alt') as string
    }, {
      name: 'og:url',
      property: 'og:url',
      content: 'https://beauty-couture-studio.cz' + route.path
    }, {
      name: 'og:locale',
      property: 'og:locale',
      content: i18n.global.locale.value
    }]
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AppHeader),
    _createVNode(_component_router_view),
    _createVNode(AppFooter)
  ], 64))
}
}

})