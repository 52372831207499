import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "parallax-content section-lg text-center" }
const _hoisted_2 = { class: "quote-modern pb-3" }
const _hoisted_3 = { class: "quote-modern-text" }
const _hoisted_4 = { class: "quote-modern-caption" }
const _hoisted_5 = { class: "quote-modern-title" }

import Carousel from 'primevue/carousel'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AppReviews',
  setup(__props) {

const reviews = ref([{
  name: 'Luna McDonald',
  text: 'So great, I always leave feeling satisfied worth the price and great nail artist - she is so sweet. Will be coming here as long as I can :) The nails don’t damage my nail beds and last a long time'
}, {
  name: 'Екатерина Костова',
  text: 'It’s a great please and great service ! Also a very cozy place with a nice interior! A wonderful chair for a pedicure and very tasty sweets for tea! The manicure and pedicure were performed to the highest quality, I was very pleased!',
}, {
  name: 'Joana Mierzwa',
  text: 'She was super sweet and gentle! Had a little trouble to find the place but she helped me 😅🙌 and I love the result!!! …',
}, {
  name: 'Lilian Naskidashvili',
  text: 'Byla jsem velmi spokojená. Klidná atmosféra, opravdu příjemná slečna, velký výběr krásných a zároveň i kvalitních barev. Výsledek je úžasný 😍 …'
}])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('reviews')), 1),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "divider-lg" }, null, -1)),
    _createVNode(_unref(Carousel), {
      value: reviews.value,
      numVisible: 1,
      numScroll: 1
    }, {
      item: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("svg", {
              class: "quote-body-mark",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              x: "0px",
              y: "0px",
              width: "66px",
              height: "49px",
              viewbox: "0 0 66 49",
              "enable-background": "new 0 0 66 49",
              "xml:space": "preserve"
            }, [
              _createElementVNode("path", { d: "M36.903,49v-3.098c9.203-5.315,14.885-12.055,17.042-20.222c-2.335,1.524-4.459,2.288-6.37,2.288,c-3.186,0-5.875-1.29-8.071-3.876c-2.194-2.583-3.293-5.74-3.293-9.479c0-4.133,1.443-7.605,4.327-10.407,C43.425,1.405,46.973,0,51.185,0c4.213,0,7.735,1.784,10.566,5.352C64.585,8.919,66,13.359,66,18.669\t\t\t\t\t\t\t\t\tc0,7.482-2.85,14.183-8.549,20.112C51.751,44.706,44.902,48.112,36.903,49z M0.69,49v-3.098\t\t\t\t\t\t\t\t\tc9.205-5.315,14.887-12.055,17.044-20.222c-2.335,1.524-4.478,2.288-6.423,2.288c-3.152,0-5.823-1.29-8.02-3.876\t\t\t\t\t\t\t\t\tC1.096,21.51,0,18.353,0,14.614c0-4.133,1.434-7.605,4.301-10.407C7.168,1.405,10.709,0,14.92,0c4.247,0,7.778,1.784,10.592,5.352\t\t\t\t\t\t\t\t\tc2.814,3.567,4.223,8.007,4.223,13.317c0,7.482-2.843,14.183-8.524,20.112C15.53,44.706,8.69,48.112,0.69,49z" })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(slotProps.data.text), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", _hoisted_5, _toDisplayString(slotProps.data.name), 1),
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "big" }, "Client", -1))
          ])
        ])
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}
}

})