import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useHead } from '@unhead/vue'
import i18n from '@/i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'ParentView',
  setup(__props) {

useHead({
  htmlAttrs: {
    lang: i18n.global.locale.value
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})