export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domů"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služby"])},
  "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
  "specialists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialisté"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
  "free_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdarma volání"])},
  "opening_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otevírací doba"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po-Pá: 9:00 - 21:00"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenze"])},
  "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní informace"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nás"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náš tým"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hod"])},
  "price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabízíme širokou škálu cenově dostupných ošetření nehtů a pokožky pro vás. Níže se můžete dozvědět více o cenovém rozpětí našich služeb."])},
  "manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manikúra"])},
  "combi_manicure_gel_polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinovaná manikúra + gel lak"])},
  "combi_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinovaná manikúra"])},
  "hard_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpevnění pomocí polygelu"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprava nehtů"])},
  "pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedikúra"])},
  "full_pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedikúra+lakování gel-lakem"])},
  "pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedikúra (jen prsty) + gel-lak"])},
  "full_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedikúra bez gel-lak"])},
  "mens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men's"])},
  "mens_pedicure_only_fingers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pánská pedikúra pouze prsty"])},
  "mens_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pánská pedikúra"])},
  "mens_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pánská manikúra"])},
  "french_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francouzská manikúra"])},
  "nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodloužení nehtů"])},
  "nail_extension_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodloužení nehtů (délka L-Xl)"])},
  "removing_nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstranění prodloužených nehtů"])},
  "correction_extended_nails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprava prodloužení"])},
  "brows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obočí"])},
  "hair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účesy"])},
  "cosmetology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosmetologie"])},
  "makeup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make-up"])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Více informací"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manikúra praha, pedicura, shellac, nehtové studio praha"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture - Kosmetický salon v Praze"])},
  "og_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture Studio - Kosmetický salon v Praze"])},
  "og_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V našem salonu vám udělají manikúru/pedikúru, líčení, účes, kosmetické procedury na kosmetice světových značek. Sterilizace. Procedury pro muže."])},
  "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manikúra, pedikúra, make-up, kosmetický služby v Praze"])},
  "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manikúra Praha"])},
  "expert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitelka salonu"])},
  "expert_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatiana"])},
  "expert_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakladatelka salonu Beauty Couture Studio.<br/>Specialistka na manikúru a pedikúru.<br/><br/>Hygienická manikúra a pedikúra pro muže a ženy.<br/>Manikúra s gelovým/gel-lakovým nátěrem.<br/>Prodlužování nehtů.<br/>Spa péče o ruce a nohy."])},
  "expert1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizážistka"])},
  "expert1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
  "expert1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nude make-up za 40 minut.<br/>Make-up na focení, maturitní ples, narozeniny.<br/>Svatbení líčení.<br/>Služba 'Make-up + účes ve 4 rukou'<br/>Pořádá kurzy 'Sám sobě vizážistou'"])},
  "expert2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stylistka na vlasy"])},
  "expert2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelina"])},
  "expert2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úpravy pro jakoukoli událost.<br/>Svatbení účesy.<br/>Různé typy pletení.<br/>Služba 'Make-up + účes ve 4 rukou'"])},
  "expert3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosmetička"])},
  "expert3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valentina"])},
  "expert3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Péče o pleť<br/>Omlazení pokožky<br/>Léčba akné, růžovky, kuperózy, pigmentace.<br/>Pracuje s certifikovanou kosmetikou světových značek"])},
  "meet_our_experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seznamte se s našimi odborníky"])},
  "meet_our_experts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaměstnáváme nejlepší manikérky a pedikérky v oboru. Také vzděláváme naše vlastní oceňované specialisty prostřednictvím neustálého školení. Zde jsou nejvýznamnější talenty našeho týmu, které plní vaše sny."])}
}