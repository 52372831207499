export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
  "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
  "specialists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специалисты"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "free_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бесплатный звонок"])},
  "opening_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время работы"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПН-ПТ: 9:00 - 20:00"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы"])},
  "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про нас"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша команда"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["час"])},
  "price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предлагаем широкий спектр доступных процедур для ногтей и кожи. Ниже вы можете ознакомиться с диапазоном цен на наши услуги."])},
  "manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mаникюр"])},
  "combi_manicure_gel_polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комбинированный маникюр+гель-лак"])},
  "combi_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комбинированный маникюр"])},
  "hard_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укрепление полигелем"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ремонт ногтя"])},
  "pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Педикюр"])},
  "full_pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный педикюр + гель-лак"])},
  "pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Педикюр(только пальчики) + гель-лак"])},
  "full_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Педикюр без покрытия"])},
  "mens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской маникюр/педикюр"])},
  "mens_pedicure_only_fingers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской педикюр только пальчики"])},
  "mens_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской педикюр"])},
  "mens_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской маникюр"])},
  "french_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Французский маникюр"])},
  "nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наращивание ногтей"])},
  "nail_extension_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наращивание ногтей (длина L- Xl)"])},
  "removing_nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снятие нарощенных ногтей"])},
  "correction_extended_nails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коррекция наращивания"])},
  "brows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брови"])},
  "hair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прически"])},
  "cosmetology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косметология"])},
  "makeup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макияж"])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["маникюр, педикюр, шеллак, ногтевая студия прага, косметология, макияж"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture - Салон красоты в Праге"])},
  "og_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture Studio - Салон красоты в Праге"])},
  "og_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В нашем салоне вам сделают маникюр/педикюр, макияж, прическу, косметические процедуры на косметике мировых брендов. Стерилизация. Процедуры для мужчин."])},
  "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маникюр, педикюр, макияж, косметология в Праге"])},
  "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маникюр Прага"])},
  "expert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец салона"])},
  "expert_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Татьяна"])},
  "expert_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создательница салона Beauty Couture studio.<br/>Мастер маникюра и педикюра.<br/><br/>Гигиенический маникюр и педикюр для мужчин и женщин.<br/>Маникюр с покрытием гель/гель-лак.<br/>Наращивание ногтей.<br/>Спа уход за кожей рук и ног."])},
  "expert1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визажист"])},
  "expert1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алла"])},
  "expert1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nude make-up за 40 минут.<br/>Макияж на фотосессию, выпускной, день рождения.<br/>Свадебный образ.<br/>Услуга «Макияж + прическа в 4 руки»<br/>Проводит курсы «Сам себе визажист»"])},
  "expert2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стилист по волосам"])},
  "expert2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ангелина"])},
  "expert2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укладки на любое мероприятия.<br/>Свадебные прически.<br/>Разные виды плетений кос.<br/>Услуга «Макияж + прическа в 4 руки»"])},
  "expert3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Косметолог"])},
  "expert3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валентина"])},
  "expert3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уходовые процедуры<br/>Омоложение кожи<br/>Лечение акне, розацеа, купероза, пигментации.<br/>Работает на сертифицированной косметике мировых брендов"])},
  "meet_our_experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Познакомьтесь с нашими экспертами"])},
  "meet_our_experts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас работают лучшие мастера маникюра и педикюра в отрасли. Мы также обучаем наших собственных специалистов-победителей через постоянное обучение. Вот самые выдающиеся таланты нашей команды, которые воплощают ваши мечты в реальность."])}
}