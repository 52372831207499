<script setup lang="ts">
import ContactsComponent from '@/components/ContactsComponent.vue'
import Carousel from 'primevue/carousel'
import { ref } from 'vue'
import AppReviews from '@/components/AppReviews.vue'

// useHead({
// })

const responsiveOptions = ref([
  {
    breakpoint: '1410px',
    numVisible: 2,
    numScroll: 1
  },
  {
    breakpoint: '1000px',
    numVisible: 1,
    numScroll: 1
  }
])

const profiles = ref([{
  name: 'expert_name',
  position: 'expert_title',
  imgSrc: '/images/profiles/tania.jpg'
}, {
  name: 'expert1_name',
  position: 'expert1_title',
  imgSrc: '/images/profiles/alla.jpg'
}, {
  name: 'expert2_name',
  position: 'expert2_title',
  imgSrc: '/images/profiles/angelina.jpg'
}, {
  name: 'expert3_name',
  position: 'expert3_title',
  imgSrc: '/images/profiles/valia.jpg'
}])
</script>

<template>
  <div class="main-screen pb-5">
    <img src="/images/main_pic.jpg" alt="Background screen" title="Background screen" width="100%" height="100%" style="overflow: hidden; max-width: inherit" />
  </div>
  <!--List of services-->
  <section class="section bg-gray-11">
    <div class="row m-0 services-table">
      <div class="col-sm-4 g-0 services-table__column">
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/manicure.jpg)">
              {{ $t('manicure') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/pedicure.jpg)">
              {{ $t('pedicure') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 g-0 services-table__column">
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/brows.jpg)">
              {{ $t('brows') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/cosmetology.jpg)">
              {{ $t('cosmetology') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/makeup.jpg)">
              {{ $t('makeup') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 g-0 services-table__column">
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img" style="background-image: url(/images/services/hair.jpg)">
              {{ $t('hair') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
        <div class="services-table__cell p-2 p-md-4">
          <div class="services-table__image">
            <div class="img">
              {{ $t('prices') }}
              <span class="read-more">{{ $t('read_more') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--List of employees-->
  <section class="section section-lg bg-default text-center p-5">

    <div class="container">
      <h2>{{$t('meet_our_experts')}}</h2>
      <div class="divider-lg"></div>
      <div class="row justify-content-center p-4">
        <div class="col-md-10 col-lg-9">
          <p>{{$t('meet_our_experts_description')}}</p>
        </div>
      </div>
      <div class="row row-50 extra-negative-margin">
        <Carousel :value="profiles" :numVisible="3" :numScroll="1" :responsiveOptions="responsiveOptions" :circular="true">
          <template #item="slotProps">
            <div class="w-100 pb-4">
              <div class="app-team-member pb-3 p-2 mx-auto">
                <figure class="m-3">
                  <img :src="slotProps.data.imgSrc" :alt="'Photo ' + $t(slotProps.data.name)" width="310" height="280" style="height: 450px" />
                </figure>
                <div class="team-corporate-caption">
                  <h4 class="team-corporate-title">{{ $t(slotProps.data.name) }}</h4>
                  <div class="divider"></div>
                  <p>{{ $t(slotProps.data.position) }}</p>
                </div>
              </div>
            </div>
          </template>
        </Carousel>
        <div class="col-12 pt-5">
          <RouterLink class="button button-default-outline"
                      :to="{name: 'experts', params: {lang: $i18n.locale}}">
            {{ $t('read_more') }}
          </RouterLink>
        </div>
      </div>
    </div>
  </section>
  <!--Why Our Clients Choose Us-->
  <section class="section section-lg bg-default p-5">
    <div class="row row-50 align-items-lg-center justify-content-lg-around">
      <div class="col-md-6 col-lg-5">
        <div class="box-images-parallax">
          <div class="front-element">
            <img src="/images/home-1-387x440.png" alt="" width="387" height="440" />
          </div>
          <div class="back-element">
            <img src="/images/home-1-287x380.png" alt="" width="287" height="380" />
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-5 p-4">
        <h2 class="heading-decorate">Why Our Clients <br><span class="divider"></span>Choose Us
        </h2>
        <p class="big text-gray-800">Our salon aims to deliver the best nail design experience and top-notch customer service.</p>
        <p>We use all-natural, organic body products, hard-to-find polish brands and colors, iPads at every seat, and a drink menu featuring fresh-pressed juices and
          hand-crafted coffees.</p>
        <!--          TODO-->
        <!--          <a class="button button-default-outline" href="overview.html">read more</a>-->
      </div>
    </div>
  </section>
  <!--List of reviews-->
  <section class="section pb-4">
    <AppReviews />
  </section>
  <!--Map-->
  <ContactsComponent />

</template>
<style lang="scss" scoped>
.main-screen {
  width: 100%;
}

.app-team-member {
  max-width: 370px;
  border: 3px solid #f4f4f4;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 576px) {
  .extra-negative-margin {
    margin-left: -65px;
    margin-right: -70px;
  }
}
</style>
