// src/services/priceService.js
export async function fetchAndParseFile(url: string) {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Ошибка загрузки файла: ${response.statusText}`);
        }

        const text = await response.text();
        return parsePriceFile(text);
    } catch (error) {
        console.error('Ошибка:', error);
        throw error;
    }
}

function parsePriceFile(fileContent: string) {
    const lines = fileContent.split('\n').map(line => line.trim());
    const result: any[] = [];
    let currentCategory: any | null = null;

    lines.forEach(line => {
        if (line.includes('|')) {
            const [name, price, time] = line.split('|').map(item => item.trim());
            if (currentCategory) {
                const totalDuration = parseInt(time, 10);
                const durationInHours = Math.floor(totalDuration / 60);
                const durationInMinutes = totalDuration - durationInHours * 60;
                currentCategory.list.push({ name, price: parseInt(price, 10), durationInMinutes, durationInHours});
            }
        } else if (line) {
            currentCategory = {
                service: line,
                list: []
            };
            result.push(currentCategory);
        }
    });

    return result;
}
