import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section bg-gray-11" }
const _hoisted_2 = { class: "row m-0 services-table" }
const _hoisted_3 = { class: "col-sm-4 g-0 services-table__column" }
const _hoisted_4 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_5 = { class: "services-table__image" }
const _hoisted_6 = {
  class: "img",
  style: {"background-image":"url(/images/services/manicure.jpg)"}
}
const _hoisted_7 = { class: "read-more" }
const _hoisted_8 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_9 = { class: "services-table__image" }
const _hoisted_10 = {
  class: "img",
  style: {"background-image":"url(/images/services/pedicure.jpg)"}
}
const _hoisted_11 = { class: "read-more" }
const _hoisted_12 = { class: "col-sm-4 g-0 services-table__column" }
const _hoisted_13 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_14 = { class: "services-table__image" }
const _hoisted_15 = {
  class: "img",
  style: {"background-image":"url(/images/services/brows.jpg)"}
}
const _hoisted_16 = { class: "read-more" }
const _hoisted_17 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_18 = { class: "services-table__image" }
const _hoisted_19 = {
  class: "img",
  style: {"background-image":"url(/images/services/cosmetology.jpg)"}
}
const _hoisted_20 = { class: "read-more" }
const _hoisted_21 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_22 = { class: "services-table__image" }
const _hoisted_23 = {
  class: "img",
  style: {"background-image":"url(/images/services/makeup.jpg)"}
}
const _hoisted_24 = { class: "read-more" }
const _hoisted_25 = { class: "col-sm-4 g-0 services-table__column" }
const _hoisted_26 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_27 = { class: "services-table__image" }
const _hoisted_28 = {
  class: "img",
  style: {"background-image":"url(/images/services/hair.jpg)"}
}
const _hoisted_29 = { class: "read-more" }
const _hoisted_30 = { class: "services-table__cell p-2 p-md-4" }
const _hoisted_31 = { class: "services-table__image" }
const _hoisted_32 = { class: "img" }
const _hoisted_33 = { class: "read-more" }
const _hoisted_34 = { class: "section section-lg bg-default text-center p-5" }
const _hoisted_35 = { class: "container" }
const _hoisted_36 = { class: "row justify-content-center p-4" }
const _hoisted_37 = { class: "col-md-10 col-lg-9" }
const _hoisted_38 = { class: "row row-50 extra-negative-margin" }
const _hoisted_39 = { class: "w-100 pb-4" }
const _hoisted_40 = { class: "app-team-member pb-3 p-2 mx-auto" }
const _hoisted_41 = { class: "m-3" }
const _hoisted_42 = ["src", "alt"]
const _hoisted_43 = { class: "team-corporate-caption" }
const _hoisted_44 = { class: "team-corporate-title" }
const _hoisted_45 = { class: "col-12 pt-5" }
const _hoisted_46 = { class: "section pb-4" }

import ContactsComponent from '@/components/ContactsComponent.vue'
import Carousel from 'primevue/carousel'
import { ref } from 'vue'
import AppReviews from '@/components/AppReviews.vue'

// useHead({
// })


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const responsiveOptions = ref([
  {
    breakpoint: '1410px',
    numVisible: 2,
    numScroll: 1
  },
  {
    breakpoint: '1000px',
    numVisible: 1,
    numScroll: 1
  }
])

const profiles = ref([{
  name: 'expert_name',
  position: 'expert_title',
  imgSrc: '/images/profiles/tania.jpg'
}, {
  name: 'expert1_name',
  position: 'expert1_title',
  imgSrc: '/images/profiles/alla.jpg'
}, {
  name: 'expert2_name',
  position: 'expert2_title',
  imgSrc: '/images/profiles/angelina.jpg'
}, {
  name: 'expert3_name',
  position: 'expert3_title',
  imgSrc: '/images/profiles/valia.jpg'
}])

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "main-screen pb-5" }, [
      _createElementVNode("img", {
        src: "/images/main_pic.jpg",
        alt: "Background screen",
        title: "Background screen",
        width: "100%",
        height: "100%",
        style: {"overflow":"hidden","max-width":"inherit"}
      })
    ], -1)),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t('manicure')) + " ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createTextVNode(_toDisplayString(_ctx.$t('pedicure')) + " ", 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createTextVNode(_toDisplayString(_ctx.$t('brows')) + " ", 1),
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createTextVNode(_toDisplayString(_ctx.$t('cosmetology')) + " ", 1),
                _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createTextVNode(_toDisplayString(_ctx.$t('makeup')) + " ", 1),
                _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createTextVNode(_toDisplayString(_ctx.$t('hair')) + " ", 1),
                _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createTextVNode(_toDisplayString(_ctx.$t('prices')) + " ", 1),
                _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('read_more')), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_34, [
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('meet_our_experts')), 1),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider-lg" }, null, -1)),
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('meet_our_experts_description')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_38, [
          _createVNode(_unref(Carousel), {
            value: profiles.value,
            numVisible: 3,
            numScroll: 1,
            responsiveOptions: responsiveOptions.value,
            circular: true
          }, {
            item: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("figure", _hoisted_41, [
                    _createElementVNode("img", {
                      src: slotProps.data.imgSrc,
                      alt: 'Photo ' + _ctx.$t(slotProps.data.name),
                      width: "310",
                      height: "280",
                      style: {"height":"450px"}
                    }, null, 8, _hoisted_42)
                  ]),
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("h4", _hoisted_44, _toDisplayString(_ctx.$t(slotProps.data.name)), 1),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t(slotProps.data.position)), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["value", "responsiveOptions"]),
          _createElementVNode("div", _hoisted_45, [
            _createVNode(_component_RouterLink, {
              class: "button button-default-outline",
              to: {name: 'experts', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('read_more')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ])
    ]),
    _cache[3] || (_cache[3] = _createStaticVNode("<section class=\"section section-lg bg-default p-5\" data-v-63a11626><div class=\"row row-50 align-items-lg-center justify-content-lg-around\" data-v-63a11626><div class=\"col-md-6 col-lg-5\" data-v-63a11626><div class=\"box-images-parallax\" data-v-63a11626><div class=\"front-element\" data-v-63a11626><img src=\"/images/home-1-387x440.png\" alt=\"\" width=\"387\" height=\"440\" data-v-63a11626></div><div class=\"back-element\" data-v-63a11626><img src=\"/images/home-1-287x380.png\" alt=\"\" width=\"287\" height=\"380\" data-v-63a11626></div></div></div><div class=\"col-md-6 col-xl-5 p-4\" data-v-63a11626><h2 class=\"heading-decorate\" data-v-63a11626>Why Our Clients <br data-v-63a11626><span class=\"divider\" data-v-63a11626></span>Choose Us </h2><p class=\"big text-gray-800\" data-v-63a11626>Our salon aims to deliver the best nail design experience and top-notch customer service.</p><p data-v-63a11626>We use all-natural, organic body products, hard-to-find polish brands and colors, iPads at every seat, and a drink menu featuring fresh-pressed juices and hand-crafted coffees.</p></div></div></section>", 1)),
    _createElementVNode("section", _hoisted_46, [
      _createVNode(AppReviews)
    ]),
    _createVNode(ContactsComponent)
  ], 64))
}
}

})