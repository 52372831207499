import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container app-experts" }
const _hoisted_2 = { class: "text-center p-3 pt-xl-5" }
const _hoisted_3 = { class: "py-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 col-md-6 col-xl-4 py-3" }

import AppExpertCard from '@/components/AppExpertCard.vue'
import { ref } from 'vue'
import AppMainExpertCard from '@/components/AppMainExpertCard.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExpertsView',
  setup(__props) {

const main = ref({
  title: 'expert_title',
  name: 'expert_name',
  image: 'tania.jpg',
  description: 'expert_description'
})
const experts = ref([{
  title: 'expert1_title',
  name: 'expert1_name',
  image: 'alla.jpg',
  description: 'expert1_description'
}, {
  title: 'expert2_title',
  name: 'expert2_name',
  image: 'angelina.jpg',
  description: 'expert2_description'
}, {
  title: 'expert3_title',
  name: 'expert3_name',
  image: 'valia.jpg',
  description: 'expert3_description'
}])

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('our_team')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(AppMainExpertCard, {
        title: main.value.title,
        name: main.value.name,
        description: main.value.description,
        image: main.value.image
      }, null, 8, ["title", "name", "description", "image"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(experts.value, (expert) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(AppExpertCard, {
            title: expert.title,
            name: expert.name,
            description: expert.description,
            image: expert.image
          }, null, 8, ["title", "name", "description", "image"])
        ]))
      }), 256))
    ])
  ]))
}
}

})