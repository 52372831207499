import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

export const authorisedLanguages: string[] = ['cz', 'en', 'ru']
export const locales = [{
  lang: 'cz',
  locale: 'cz-cz'
}, {
  lang: 'en',
  locale: 'en-us'
}, {
  lang: 'ru',
  locale: 'ru-ru'
}]

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

const lang = window.location.pathname.split('/', 2)[1]
const locale = authorisedLanguages.includes(lang) ? lang : 'cz'

export default createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cz',
  messages: loadLocaleMessages() as any
})
