import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section section-md bg-default text-center pt-5 app-prices" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "app-prices__title" }
const _hoisted_4 = { class: "block-sm" }
const _hoisted_5 = { class: "row justify-content-center" }
const _hoisted_6 = {
  id: "accordion",
  class: "accordion-prices2"
}
const _hoisted_7 = { class: "pt-5 pb-3" }
const _hoisted_8 = { class: "col-12 row g-0" }
const _hoisted_9 = { class: "col-12 col-md-6 text-start p-3 pe-md-5 d-flex" }
const _hoisted_10 = { class: "flex-grow-1" }
const _hoisted_11 = { class: "app-prices__price-item-name" }
const _hoisted_12 = { class: "text-end px-2 flex-grow-1 text-nowrap" }
const _hoisted_13 = { class: "app-prices__duration" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  class: "flex-shrink-0",
  style: {"flex":"0 0 80px"}
}

import { ref, watch } from 'vue'
import { fetchAndParseFile } from '@/services/priceService';
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";



export default /*@__PURE__*/_defineComponent({
  __name: 'PricesView',
  setup(__props) {

const prices = ref([] as any[]);

const locale = i18n.global.locale;

// Наблюдение за глобальным изменением языка
watch(locale, (newLocale, oldLocale) => {
  initPrices(newLocale);
});

function initPrices(lang: string) {
  const url = '/prices_'+lang+'.txt'; // Замените на URL вашего файла
  fetchAndParseFile(url).then(categories => {
    prices.value = categories;
  });
}

initPrices(locale.value);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('prices')), 1),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider-lg" }, null, -1)),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('price_description')), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prices.value, (price) => {
            return (_openBlock(), _createElementBlock("div", null, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(price.service), 1),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(price.list, (priceItem) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("h5", _hoisted_11, _toDisplayString(priceItem.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("p", _hoisted_13, [
                        (priceItem.durationInHours > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(priceItem.durationInHours) + " " + _toDisplayString(_ctx.$t('hr')) + " ", 1))
                          : _createCommentVNode("", true),
                        (priceItem.durationInMinutes > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(priceItem.durationInMinutes) + " " + _toDisplayString(_ctx.$t('min')), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("h5", null, _toDisplayString(priceItem.price) + " Kč", 1)
                    ])
                  ]))
                }), 256))
              ])
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}
}

})