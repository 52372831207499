import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section bg-default section-sm footer-modern app-footer-info mx-5 my-5" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row row-50" }
const _hoisted_4 = { class: "col-sm-6 p-2" }
const _hoisted_5 = { class: "footer-title" }
const _hoisted_6 = { class: "col-sm-6 p-2" }
const _hoisted_7 = { class: "footer-title" }
const _hoisted_8 = { class: "list" }
const _hoisted_9 = { class: "app-footer row p-3 m-0" }
const _hoisted_10 = { class: "col-12 col-md-4 text-center text-md-start py-1 py-md-0" }
const _hoisted_11 = { class: "col-12 col-md-4 text-center text-md-end py-3 py-md-0" }

import AppSocialBlock from '@/components/AppSocialBlock.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('about_us')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider-lg" }, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Beauty Couture Studio has been offering an extensive range of nail services and skin treatments since 2024.", -1))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('opening_hours')), 1),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider-lg" }, null, -1)),
            _createElementVNode("ul", _hoisted_8, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('hours')), 1),
              _cache[2] || (_cache[2] = _createElementVNode("li", null, "Sunday: for order", -1))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_RouterLink, {
          to: {name: 'home', params: {lang: _ctx.$i18n.locale}}
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              src: "/images/logo1.png",
              alt: "Logo",
              title: "Logo",
              width: "133",
              height: "31"
            }, null, -1)
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col-12 col-md-4 text-center py-3 py-md-0" }, [
        _createElementVNode("p", { class: "rights" }, [
          _createElementVNode("span", null, "©  "),
          _createElementVNode("span", { class: "copyright-year" }),
          _createElementVNode("span", null, " "),
          _createElementVNode("span", null, "Beauty Couture Studio"),
          _createElementVNode("span", null, ". ")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(AppSocialBlock)
      ])
    ])
  ], 64))
}
}

})