import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "about" }

import { useHead } from '@unhead/vue'
import i18n from '@/i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'ServicesView',
  setup(__props) {

useHead({
  meta: [{
    name: 'description',
    content: 'Services | Beauty Couture Studio'
  }]
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", null, "This is an services page", -1)
  ])))
}
}

})