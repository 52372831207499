<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'


import { useHead } from '@unhead/vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const translateService = useI18n()

useHead({
  title: translateService.t('title'),
  meta: [
    {
      name: 'name',
      property: 'name',
      content: translateService.t('og_title') as string
    },
    {
      name: 'description',
      property: 'description',
      content: translateService.t('og_description') as string
    },

    {
      name: 'og:title',
      property: 'og:title',
      content: translateService.t('og_title') as string
    }, {
      name: 'og:description',
      property: 'og:description',
      content: translateService.t('og_description') as string
    }, {
      name: 'twitter:title',
      property: 'twitter:title',
      content: translateService.t('og_title') as string
    }, {
      name: 'twitter:description',
      property: 'twitter:description',
      content: translateService.t('og_description') as string
    }, {
      name: 'description',
      property: 'description',
      content: translateService.t('og_description') as string
    }, {
      name: 'generator',
      property: 'generator',
      content: translateService.t('og_title') as string
    }, {
      name: 'twitter:image:alt',
      property: 'twitter:image:alt',
      content: translateService.t('image_alt') as string
    }, {
      name: 'og:url',
      property: 'og:url',
      content: 'https://beauty-couture-studio.cz' + route.path
    }, {
      name: 'og:locale',
      property: 'og:locale',
      content: i18n.global.locale.value
    }]
})
</script>
<template>
  <AppHeader />
  <router-view />
  <AppFooter />
</template>

