<script setup lang="ts">
import { ref } from 'vue'
import { useFixedHeader } from 'vue-use-fixed-header'
import { useRoute } from 'vue-router'
import i18n, { authorisedLanguages } from '@/i18n'
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import router from '@/router'
import AppSocialBlock from '@/components/AppSocialBlock.vue'

const headerRef = ref(null)
const route = useRoute();
const options = ref(authorisedLanguages);
const { styles } = useFixedHeader(headerRef)

const model = ref({
  open: false
})



function opened(n: boolean){
  model.value.open = n;
}

function redirect(lang: string){
  router.push({name: route.name as string, params: {lang: lang}});
}

const visibleLeft = ref(false);

const changeLang = (args: any) =>{
  console.log("Change lang: " + args);
}

</script>

<template>
  <!-- Page Header-->
  <header class="section page-header2 Header" ref="headerRef" :style="styles">
    <!-- RD Navbar-->
    <div class="rd-navbar-wrap">
      <nav class="rd-navbar rd-navbar-classic rd-navbar-classic-center">

        <!--        LINE-->
        <div class="app-header-line d-none d-lg-inline-block">
          <div class="app-header-line__info">
            <div class="app-header-line__info-item">
              <span class="app-header-line__info-item__text">{{ $t('free_call') }}:</span>
              <a class="app-header-line__info-item__value" href="tel:+420 702 094 842">+420 702 094 842</a>
            </div>
            <div class="app-header-line__info-item">
              <span class="app-header-line__info-item__text">{{ $t('opening_hours') }}:</span>
              <span class="app-header-line__info-item__value">{{ $t('hours') }}</span>
            </div>
          </div>
          <div class="app-header-line__social-block">
            <ul class="list-inline">
              <li><a class="fa fa-phone" href="tel:+420 702 094 842" target="_blank"></a></li>
              <li><a class="fa fa-instagram" href="https://www.instagram.com/beauty_couture_studio" target="_blank"></a></li>
              <li><a class="fa fa-facebook" href="https://www.facebook.com/nailcoutureprague" target="_blank"></a></li>
              <li><a class="fa fa-whatsapp" href="https://wa.me/message/Y7EDOOCYL3MIA1" target="_blank"></a></li>
              <li><a class="fa fa-telegram" href="https://t.me/beauty_couture_studio" target="_blank"></a></li>
              <li><a class="fa fa-google" href="mailto:nailcoutureprague@gmail.com" target="_blank"></a></li>
            </ul>
          </div>
        </div>
        <!--       HEADER MENU-->
        <div class="app-header-menu p-2 p-lg-4">

          <Sidebar v-model:visible="visibleLeft" :header="$t('menu')">

            <nav @click="visibleLeft = false">
              <ul class="app-header-menu__items-container popup">
                <li class="app-header-menu__item active">
                  <RouterLink class="rd-nav-link" :to="{name: 'home', params: {lang: $i18n.locale}}">{{ $t('home') }}</RouterLink>
                </li>
                <li class="app-header-menu__item ">
                  <RouterLink class="rd-nav-link" :to="{name: 'services', params: {lang: $i18n.locale}}">{{ $t('services')}}</RouterLink>
                </li>
                <li class="app-header-menu__item">
                  <RouterLink class="rd-nav-link" :to="{name: 'prices', params: {lang: $i18n.locale}}">{{ $t('prices')}}</RouterLink>
                </li>
                <li class="app-header-menu__item">
                  <RouterLink class="rd-nav-link" :to="{name: 'experts', params: {lang: $i18n.locale}}">{{ $t('specialists') }}</RouterLink>
                </li>
                <li class="app-header-menu__item">
                  <RouterLink class="rd-nav-link" :to="{name: 'contacts', params: {lang: $i18n.locale}}">{{ $t('contacts') }}</RouterLink>
                </li>
              </ul>
            </nav>

            <div style="position: absolute; bottom: 50px;">
              <div class="py-4">
                <span class="d-block">Address: </span>
<!--                <span>Prague, Zborovská 575/41, 150 00 Malá Strana</span>-->
                <a class="px-3 px-md-2" href="https://maps.app.goo.gl/osCGocoGM2UT1wJy6" target="_blank">Prague, Zborovská 575/41, 150 00 Malá Strana</a>
              </div>
              <AppSocialBlock></AppSocialBlock>
            </div>
          </Sidebar>

          <div class="app-header-menu__items">
            <ul class="app-header-menu__items-container">
              <li class="d-lg-none m-0">
                  <Button class="app-header-menu__bars icon fa fa-bars"
                          @click="visibleLeft = true"></Button>
              </li>
              <li class="align-middle">
                <RouterLink class="app-header-menu__logo" :to="{name: 'home', params: {lang: $i18n.locale}}">
                  <img src="/images/logo1.png" alt="Logo" title="Logo" width="220" height="44">
                </RouterLink>
              </li>
              <li class="app-header-menu__item active d-none d-lg-inline-block">
                <RouterLink class="rd-nav-link" :to="{name: 'home', params: {lang: $i18n.locale}}">{{ $t('home') }}</RouterLink>
              </li>
<!--              <li class="app-header-menu__item d-none d-lg-inline-block">
                <RouterLink class="rd-nav-link" :to="{name: 'services', params: {lang: $i18n.locale}}">{{ $t('services')}}</RouterLink>
              </li>-->
              <li class="app-header-menu__item d-none d-lg-inline-block">
                <RouterLink class="rd-nav-link" :to="{name: 'prices', params: {lang: $i18n.locale}}">{{ $t('prices')}}</RouterLink>
              </li>
              <li class="app-header-menu__item d-none d-lg-inline-block">
                <RouterLink class="rd-nav-link" :to="{name: 'experts', params: {lang: $i18n.locale}}">{{ $t('specialists') }}</RouterLink>
              </li>
              <li class="app-header-menu__item d-none d-lg-inline-block">
                <RouterLink class="rd-nav-link" :to="{name: 'contacts', params: {lang: $i18n.locale}}">{{ $t('contacts') }}</RouterLink>
              </li>
              <div class="app-header-line__locale-changer text-end">
                <Dropdown v-model="$i18n.locale" :options="options" placeholder="Lang" class="w-full" @change="redirect($event.value)">
                  <template #optiongroup="slotProps">
                    <router-link :to="{ name: $route.name as string, params: {lang: slotProps.option}}" @click="changeLang">{{slotProps.option}}</router-link>
                  </template>
                </Dropdown>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <div class="app-header-popup" >
    <div class="app-header-popup__items" :class="{'_opened': model.open}">
      <ul class="app-header-popup__items-container">
        <li class="app-header-menu__item active">
          <RouterLink class="rd-nav-link" :to="{name: 'home', params: {lang: $i18n.locale}}">Home</RouterLink>
        </li>
        <li class="app-header-menu__item">
          <RouterLink class="rd-nav-link" :to="{name: 'services', params: {lang: $i18n.locale}}">{{ $t('services')}}</RouterLink>
        </li>
        <li class="app-header-menu__item">
          <RouterLink class="rd-nav-link" :to="{name: 'prices', params: {lang: $i18n.locale}}">{{ $t('prices')}}</RouterLink>
        </li>
        <li class="app-header-menu__item">
          <RouterLink class="rd-nav-link" :to="{name: 'experts', params: {lang: $i18n.locale}}">{{ $t('specialists') }}</RouterLink>
        </li>
        <li class="app-header-menu__item">
          <RouterLink class="rd-nav-link" :to="{name: 'contacts', params: {lang: $i18n.locale}}">{{ $t('contacts') }}</RouterLink>
        </li>
      </ul>
    </div>
  </div>

  <div class="app-header-padding"></div>
</template>
<style lang="scss">
.p-dropdown-trigger {
  display: none !important;
}
.p-dropdown {
  background-color: white;

}
.p-dropdown-label {
  color: black !important;
}
.p-button {
  background-color: transparent;
  border: none;
}
</style>
<style lang="scss" scoped>
@import "../assets/vars";

.app-header-padding {
  height: 120px;
  opacity: 0;
}

@media (max-width: 768px) {
  .app-header-padding {
    height: 55px;
  }
}

.app-header-line {
  width: 100%;
  display: inline-block;
  background-color: $main-color;


  &__info, &__info-item, &__social-block {
    display: inline-block;
    font-size: 14px;
    height: 100%;
    vertical-align: middle;
  }

  &__info {
    width: 65%;
  }

  &__info-item {
    margin: 12px 20px;
    color: #151515;
    vertical-align: middle;
    height: 100%;

    &__text {
      font-weight: 700;
      padding-right: 10px;
    }

    &__value {
      text-decoration: none;
      transition: all .3s ease-in-out;
      color: #151515 !important;
    }
  }

  &__social-block {
    text-align: right;
    width: calc(35% - 100px);
    padding-right: 20px;

    & a {
      width: 40px;
      font-size: 20px;
    }
  }

  &__locale-changer {
    display: inline-block;
  }
}

.app-header-menu {
  display: inline-block;
  width: 100%;
  //padding: 30px 10px;

  &__bars {
    color: black;
    font-size: 25px;
  }

  a {
    color: black;
  }

  &__logo {
    width: 70px;
  }

  &__items {
    display: inline-block;
    //width: calc(100% - 70px);
    width: 100%;
  }

  &__items-container {
    display: flex;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: .05em;
    text-align: left;

    & > li {
      flex: 1 1 auto;
    }

    &.popup {
      display: table-caption;
      line-height: 3;
      font-size: 24px;
    }
  }

  &__item {
    margin: auto;
  }

  // TODO
  &__item a {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      bottom: -4px;
      left: 0;
      width: 0;
      height: 3px;
      background: $main-color;
    }

    &:hover::before {
      transition: all .3s;
      width: 100%;
    }

    &.router-link-active::before {
      transition: all .3s;
      width: 100%;
    }
  }

  &__item.router-link-active a::before {
    width: 100%;
  }
}

.app-header-popup {
  position: absolute;
  float: left;

  &__items {
    transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);

    position: fixed;
    left: 0;
    top: 100px;
    bottom: -56px;
    z-index: 1079;
    width: 270px;
    padding: 112px 0 81px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    font-size: 16px;
    line-height: 34px;
    transform: translateX(-110%);
    color: #ffffff;
    background: #ffffff;
    border: 1px solid #eaeced;
    box-shadow: none;

    &._opened {
      transform: translateX(0);
    }
  }

  &__items-container {
    display: block;
    margin: 15px 0;
    height: auto;
    text-align: left;
    transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
    list-style: none;
    padding: 0;
  }
}

a {
  color: rgba(21, 21, 21, .3);
}

.Header {
  position: fixed; /* or sticky */
  top: 0;
  z-index: 999;
  background-color: white;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &:before {
    content: '';
    height: 200px;
    position: relative;
  }

  /* Other styles... */
  /* Will be disabled */
  @media (max-width: 768px) {
    .Header {
      position: relative;
    }
  }

  /* Same */
  @media (max-width: 375px) {
    .Header {
      display: none;
    }
  }
}
</style>
