export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
  "specialists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialists"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "free_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Call"])},
  "opening_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mn-Fr: 9 AM - 8 PM"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hr"])},
  "price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide a wide variety of affordable nail and skin treatments for you. Below you can learn more about the price range of our services."])},
  "manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manicure"])},
  "combi_manicure_gel_polish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combi manicure + gel polish"])},
  "combi_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combi manicure"])},
  "hard_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard gel with polygel"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single nail repair"])},
  "pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedicure"])},
  "full_pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Pedicure+Gel polish color"])},
  "pedicure_gel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedicure(only fingers) + Gel polish color"])},
  "full_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full pedicure without polish"])},
  "mens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men's"])},
  "mens_pedicure_only_fingers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men's pedicure only fingers"])},
  "mens_pedicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men's pedicure"])},
  "mens_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men's manicure"])},
  "french_manicure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French manicure"])},
  "nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nail extension"])},
  "nail_extension_xl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nail extension (length L-Xl)"])},
  "removing_nail_extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removing extensions Nails"])},
  "correction_extended_nails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сorrection of extended nails"])},
  "brows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brows"])},
  "hair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hairstyles"])},
  "cosmetology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmetology"])},
  "makeup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makeup"])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manicure prague, pedicure, shellac, nail studio prague"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture - Beauty salon in Prague"])},
  "og_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty Couture Studio - Beauty salon in Prague"])},
  "og_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our salon you will make manicure/pedicure, makeup, hairstyle, cosmetic procedures on cosmetics of world brands. Sterilization. Procedures for men."])},
  "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manicure, pedicure, make-up, cosmetology in Prague"])},
  "image_alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manicure Prague"])},
  "expert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salon Owner"])},
  "expert_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatyana"])},
  "expert_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder of Beauty Couture Studio.<br/>Manicure and Pedicure Specialist.<br/><br/>Hygienic manicure and pedicure for men and women.<br/>Manicure with gel/gel polish.<br/>Nail extensions.<br/>Spa care for hands and feet."])},
  "expert1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makeup Artist"])},
  "expert1_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla"])},
  "expert1_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nude makeup in 40 minutes.<br/>Makeup for photoshoots, proms, birthdays.<br/>Wedding look.<br/>Service 'Makeup + Hairstyle by 4 hands'<br/>Conducts courses 'Self Makeup Artist'"])},
  "expert2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hair Stylist"])},
  "expert2_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelina"])},
  "expert2_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styling for any event.<br/>Wedding hairstyles.<br/>Various types of braiding.<br/>Service 'Makeup + Hairstyle by 4 hands'"])},
  "expert3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmetologist"])},
  "expert3_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valentina"])},
  "expert3_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skincare treatments<br/>Skin rejuvenation<br/>Treatment of acne, rosacea, couperose, pigmentation.<br/>Works with certified cosmetics from global brands"])},
  "meet_our_experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meet Our Experts"])},
  "meet_our_experts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We employ the best manicurists and pedicurists in the industry. We also educate our own award-winning specialists through constant training. Here are the most prominent talents of our team who make your dreams come true."])}
}