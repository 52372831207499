<script setup lang="ts">
import { ref, watch } from 'vue'
import { fetchAndParseFile } from '@/services/priceService';
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";


const prices = ref([] as any[]);

const locale = i18n.global.locale;

// Наблюдение за глобальным изменением языка
watch(locale, (newLocale, oldLocale) => {
  initPrices(newLocale);
});

function initPrices(lang: string) {
  const url = '/prices_'+lang+'.txt'; // Замените на URL вашего файла
  fetchAndParseFile(url).then(categories => {
    prices.value = categories;
  });
}

initPrices(locale.value);

</script>
<template>
  <!--List of pricing TODO-->
  <section class="section section-md bg-default text-center pt-5 app-prices">
    <div class="container">
      <h1 class="app-prices__title">{{ $t('prices')}}</h1>
      <div class="divider-lg"></div>
      <p class="block-sm">{{ $t('price_description')}}</p>
      <div class="row justify-content-center">
        <div id="accordion" class="accordion-prices2">
          <div v-for="price in prices">
            <h3 class="pt-5 pb-3">{{ price.service }}</h3>
            <div class="col-12 row g-0">
              <div class="col-12 col-md-6 text-start p-3 pe-md-5 d-flex"
                   v-for="priceItem in price.list">
                <div class="flex-grow-1">
                  <h5 class="app-prices__price-item-name">{{ priceItem.name }}</h5>
                </div>
                <div class="text-end px-2 flex-grow-1 text-nowrap">
                  <p class="app-prices__duration">
                    <span v-if="priceItem.durationInHours > 0">{{priceItem.durationInHours}} {{ $t('hr')}}&nbsp;</span>
                    <span v-if="priceItem.durationInMinutes > 0">{{priceItem.durationInMinutes}} {{ $t('min')}} </span>
                  </p>
                </div>
                <div class="flex-shrink-0" style="flex: 0 0 80px;">
                  <h5>{{ priceItem.price }} Kč</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.app-prices {

  &__title {
    animation-name: animation_font_size;
    animation-duration: 1s;
    font-weight: 500;
  }

  &__price-item-name {
    animation-name: animation_spacing;
    animation-duration: 1s;
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .app-prices__duration, h5 {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .app-prices__duration, h5 {
    font-size: 14px;
  }
}

@keyframes animation_font_size {
  from {
    font-size: 20px;
  }
}

@keyframes animation_spacing {
  from {
    letter-spacing: -5px;
  }
}

</style>
