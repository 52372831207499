import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rd-navbar-wrap" }
const _hoisted_2 = { class: "rd-navbar rd-navbar-classic rd-navbar-classic-center" }
const _hoisted_3 = { class: "app-header-line d-none d-lg-inline-block" }
const _hoisted_4 = { class: "app-header-line__info" }
const _hoisted_5 = { class: "app-header-line__info-item" }
const _hoisted_6 = { class: "app-header-line__info-item__text" }
const _hoisted_7 = { class: "app-header-line__info-item" }
const _hoisted_8 = { class: "app-header-line__info-item__text" }
const _hoisted_9 = { class: "app-header-line__info-item__value" }
const _hoisted_10 = { class: "app-header-menu p-2 p-lg-4" }
const _hoisted_11 = { class: "app-header-menu__items-container popup" }
const _hoisted_12 = { class: "app-header-menu__item active" }
const _hoisted_13 = { class: "app-header-menu__item" }
const _hoisted_14 = { class: "app-header-menu__item" }
const _hoisted_15 = { class: "app-header-menu__item" }
const _hoisted_16 = { class: "app-header-menu__item" }
const _hoisted_17 = { style: {"position":"absolute","bottom":"50px"} }
const _hoisted_18 = { class: "app-header-menu__items" }
const _hoisted_19 = { class: "app-header-menu__items-container" }
const _hoisted_20 = { class: "d-lg-none m-0" }
const _hoisted_21 = { class: "align-middle" }
const _hoisted_22 = { class: "app-header-menu__item active d-none d-lg-inline-block" }
const _hoisted_23 = { class: "app-header-menu__item d-none d-lg-inline-block" }
const _hoisted_24 = { class: "app-header-menu__item d-none d-lg-inline-block" }
const _hoisted_25 = { class: "app-header-menu__item d-none d-lg-inline-block" }
const _hoisted_26 = { class: "app-header-line__locale-changer text-end" }
const _hoisted_27 = { class: "app-header-popup" }
const _hoisted_28 = { class: "app-header-popup__items-container" }
const _hoisted_29 = { class: "app-header-menu__item active" }
const _hoisted_30 = { class: "app-header-menu__item" }
const _hoisted_31 = { class: "app-header-menu__item" }
const _hoisted_32 = { class: "app-header-menu__item" }
const _hoisted_33 = { class: "app-header-menu__item" }

import { ref } from 'vue'
import { useFixedHeader } from 'vue-use-fixed-header'
import { useRoute } from 'vue-router'
import i18n, { authorisedLanguages } from '@/i18n'
import Dropdown from 'primevue/dropdown';
import Sidebar from 'primevue/sidebar';
import Button from 'primevue/button';
import router from '@/router'
import AppSocialBlock from '@/components/AppSocialBlock.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const headerRef = ref(null)
const route = useRoute();
const options = ref(authorisedLanguages);
const { styles } = useFixedHeader(headerRef)

const model = ref({
  open: false
})



function opened(n: boolean){
  model.value.open = n;
}

function redirect(lang: string){
  router.push({name: route.name as string, params: {lang: lang}});
}

const visibleLeft = ref(false);

const changeLang = (args: any) =>{
  console.log("Change lang: " + args);
}


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", {
      class: "section page-header2 Header",
      ref_key: "headerRef",
      ref: headerRef,
      style: _normalizeStyle(_unref(styles))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('free_call')) + ":", 1),
                _cache[5] || (_cache[5] = _createElementVNode("a", {
                  class: "app-header-line__info-item__value",
                  href: "tel:+420 702 094 842"
                }, "+420 702 094 842", -1))
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('opening_hours')) + ":", 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('hours')), 1)
              ])
            ]),
            _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"app-header-line__social-block\" data-v-6eaab34e><ul class=\"list-inline\" data-v-6eaab34e><li data-v-6eaab34e><a class=\"fa fa-phone\" href=\"tel:+420 702 094 842\" target=\"_blank\" data-v-6eaab34e></a></li><li data-v-6eaab34e><a class=\"fa fa-instagram\" href=\"https://www.instagram.com/beauty_couture_studio\" target=\"_blank\" data-v-6eaab34e></a></li><li data-v-6eaab34e><a class=\"fa fa-facebook\" href=\"https://www.facebook.com/nailcoutureprague\" target=\"_blank\" data-v-6eaab34e></a></li><li data-v-6eaab34e><a class=\"fa fa-whatsapp\" href=\"https://wa.me/message/Y7EDOOCYL3MIA1\" target=\"_blank\" data-v-6eaab34e></a></li><li data-v-6eaab34e><a class=\"fa fa-telegram\" href=\"https://t.me/beauty_couture_studio\" target=\"_blank\" data-v-6eaab34e></a></li><li data-v-6eaab34e><a class=\"fa fa-google\" href=\"mailto:nailcoutureprague@gmail.com\" target=\"_blank\" data-v-6eaab34e></a></li></ul></div>", 1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(Sidebar), {
              visible: visibleLeft.value,
              "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((visibleLeft).value = $event)),
              header: _ctx.$t('menu')
            }, {
              default: _withCtx(() => [
                _createElementVNode("nav", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (visibleLeft.value = false))
                }, [
                  _createElementVNode("ul", _hoisted_11, [
                    _createElementVNode("li", _hoisted_12, [
                      _createVNode(_component_RouterLink, {
                        class: "rd-nav-link",
                        to: {name: 'home', params: {lang: _ctx.$i18n.locale}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('home')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("li", _hoisted_13, [
                      _createVNode(_component_RouterLink, {
                        class: "rd-nav-link",
                        to: {name: 'services', params: {lang: _ctx.$i18n.locale}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('services')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("li", _hoisted_14, [
                      _createVNode(_component_RouterLink, {
                        class: "rd-nav-link",
                        to: {name: 'prices', params: {lang: _ctx.$i18n.locale}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('prices')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("li", _hoisted_15, [
                      _createVNode(_component_RouterLink, {
                        class: "rd-nav-link",
                        to: {name: 'experts', params: {lang: _ctx.$i18n.locale}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('specialists')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _createElementVNode("li", _hoisted_16, [
                      _createVNode(_component_RouterLink, {
                        class: "rd-nav-link",
                        to: {name: 'contacts', params: {lang: _ctx.$i18n.locale}}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('contacts')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "py-4" }, [
                    _createElementVNode("span", { class: "d-block" }, "Address: "),
                    _createElementVNode("a", {
                      class: "px-3 px-md-2",
                      href: "https://maps.app.goo.gl/osCGocoGM2UT1wJy6",
                      target: "_blank"
                    }, "Prague, Zborovská 575/41, 150 00 Malá Strana")
                  ], -1)),
                  _createVNode(AppSocialBlock)
                ])
              ]),
              _: 1
            }, 8, ["visible", "header"]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("ul", _hoisted_19, [
                _createElementVNode("li", _hoisted_20, [
                  _createVNode(_unref(Button), {
                    class: "app-header-menu__bars icon fa fa-bars",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (visibleLeft.value = true))
                  })
                ]),
                _createElementVNode("li", _hoisted_21, [
                  _createVNode(_component_RouterLink, {
                    class: "app-header-menu__logo",
                    to: {name: 'home', params: {lang: _ctx.$i18n.locale}}
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("img", {
                        src: "/images/logo1.png",
                        alt: "Logo",
                        title: "Logo",
                        width: "220",
                        height: "44"
                      }, null, -1)
                    ])),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", _hoisted_22, [
                  _createVNode(_component_RouterLink, {
                    class: "rd-nav-link",
                    to: {name: 'home', params: {lang: _ctx.$i18n.locale}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('home')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", _hoisted_23, [
                  _createVNode(_component_RouterLink, {
                    class: "rd-nav-link",
                    to: {name: 'prices', params: {lang: _ctx.$i18n.locale}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('prices')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", _hoisted_24, [
                  _createVNode(_component_RouterLink, {
                    class: "rd-nav-link",
                    to: {name: 'experts', params: {lang: _ctx.$i18n.locale}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('specialists')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("li", _hoisted_25, [
                  _createVNode(_component_RouterLink, {
                    class: "rd-nav-link",
                    to: {name: 'contacts', params: {lang: _ctx.$i18n.locale}}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('contacts')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_unref(Dropdown), {
                    modelValue: _ctx.$i18n.locale,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
                    options: options.value,
                    placeholder: "Lang",
                    class: "w-full",
                    onChange: _cache[4] || (_cache[4] = ($event: any) => (redirect($event.value)))
                  }, {
                    optiongroup: _withCtx((slotProps) => [
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.$route.name as string, params: {lang: slotProps.option}},
                        onClick: changeLang
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(slotProps.option), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "options"])
                ])
              ])
            ])
          ])
        ])
      ])
    ], 4),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", {
        class: _normalizeClass(["app-header-popup__items", {'_opened': model.value.open}])
      }, [
        _createElementVNode("ul", _hoisted_28, [
          _createElementVNode("li", _hoisted_29, [
            _createVNode(_component_RouterLink, {
              class: "rd-nav-link",
              to: {name: 'home', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Home")
              ])),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_30, [
            _createVNode(_component_RouterLink, {
              class: "rd-nav-link",
              to: {name: 'services', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('services')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_31, [
            _createVNode(_component_RouterLink, {
              class: "rd-nav-link",
              to: {name: 'prices', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('prices')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_32, [
            _createVNode(_component_RouterLink, {
              class: "rd-nav-link",
              to: {name: 'experts', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('specialists')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("li", _hoisted_33, [
            _createVNode(_component_RouterLink, {
              class: "rd-nav-link",
              to: {name: 'contacts', params: {lang: _ctx.$i18n.locale}}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('contacts')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ], 2)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "app-header-padding" }, null, -1))
  ], 64))
}
}

})