<script setup lang="ts">
import { useHead } from '@unhead/vue'
import i18n from '@/i18n'

useHead({
  meta: [{
    name: 'description',
    content: 'Services | Beauty Couture Studio'
  }]
})
</script>

<template>
  <div class="about">
    <h1>This is an services page</h1>
  </div>
</template>

<style>
@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
